<script>
import moment from 'moment'
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import QrCode from '../../../components/QrCode'

export default {
  name: 'toSignUp',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    const { id } = this.$route.query
    api.command.getList.call(this, {
      url: '/farmActivityUser/page',
      current: 1,
      paramsValue: {
        activityId: id
      }
    })
  },
  methods: {
    getHeader() {
      return []
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-120',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'nickname',
          title: '用户',
          type: 'lt-100',
          customRender: function(text, records) {
            return (
              <div class="product-order-item">
                <img src={records.headImg} />
                <div>{records.nickname}</div>
              </div>
            )
          },
          onExport: (text, records) => {
            return `头像${records.headImg} 昵称${records.nickname}`
          },
          sorter: (a, b) => a.nickname.length - b.nickname.length
        },
        {
          dataIndex: 'phone',
          title: '手机号',
          type: 'lt-100',
          sorter: (a, b) => a.phone - b.phone
        },
        {
          dataIndex: 'showType',
          title: '报名来源',
          width: 80,
          align: 'left',
          filters: [
            {
              text: '首页',
              value: '1'
            },
            {
              text: '商城',
              value: '2'
            }
          ],
          filterMultiple: false
        },
        {
          dataIndex: 'createTime',
          title: '报名时间',
          width: 80,
          align: 'center',
          sorter: (a, b) => moment(a.createTime).diff(moment(b.createTime))
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          width: 80,
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '查看',
                onClick: () => {
                  this.handlePropup(records)
                }
              }
            ]
          }
        }
      ]
    },
    handlePropupData(source, formData) {
      const form = [
        {
          title: '基本信息',
          form: [
            {
              name: '报名人',
              type: 'text',
              key: 'nickname',
              cols: 12
            },
            {
              name: '报名来源：',
              key: '',
              type: 'text',
              cols: 12
            },
            {
              name: '手机号',
              key: 'phone',
              type: 'text',
              cols: 12
            }
          ]
        }
      ]
      return form
    },
    handlePropup(records) {
      apiTool.showDrawer({
        title: '报名信息',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: formData => this.handlePropupData(records, formData),
          form: records,
          formProps: {
            layout: 'horizontal',
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
          }
        },
        success: ({ data, setHidden }) => {},
        foot: ({ close, submit }) => {
          return [
            {
              name: '关闭',
              onClick: close
            }
            // {
            //   name: '确认',
            //   type: 'primary',
            //   onClick: submit
            // }
          ]
        }
      })
    },
    getButton() {
      return []
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        // headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
<style scoped lang="less">
.product-order-item {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
</style>
